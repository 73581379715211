import { io } from "socket.io-client";

const socket = io("https://jellyfish-app-6lqx7.ondigitalocean.app/");
//const socket = io("http://localhost:8080/");

var multiAnswerContainer = document.getElementById("multi-answer-container");
var textAnswerContainer = document.getElementById("text-answer-container");
var choicesContainer = document.getElementById("choices-container");

var questionTitle = document.getElementById("question-title");

var answerInput = document.getElementById("answer-input");

var submitButton = document.getElementById("submit-button");
var sendText = document.getElementById("send-text");
var sendBar = document.getElementById("send-progress-bar");

var currentChoice = "";
var choiceAnswer = false;

socket.on('connect', () => {
    console.log("connected!");
});

socket.on('question was set', (data) => {
    questionTitle.innerText = data.Title;
    currentChoice = "";
    window.scrollTo(0, 0);
    endAnimateProgressBar();
    
    if (data.Choices.length > 0) {
        multiAnswerContainer.style.display = "flex";
        textAnswerContainer.style.display = "none";
        choiceAnswer = true;

        var choiceElements = [];
        data.Choices.forEach(element => {
            var div = document.createElement("div");
            div.className = "choice-text"
            div.innerText = element;

            div.onclick = () => {
                choiceElements.forEach(e => {
                    e.classList.remove("selected");
                });
                currentChoice = element;
                div.classList.add("selected");
            };

            choiceElements.push(div);
        });

        choicesContainer.replaceChildren(...choiceElements);
    }
    else {
        multiAnswerContainer.style.display = "none";
        textAnswerContainer.style.display = "flex";
        choiceAnswer = false;
    }
});

submitButton.onclick = () => {
    if (sendBar.classList.contains("animate-bar"))
        return;

    if (choiceAnswer) {
        if (currentChoice == "") return;
        animateProgressBar();
        socket.emit("send choice answer", { userId: socket.id, text: currentChoice });
    } else {
        submitText();
    }
};

answerInput.onkeydown = (e) => {
    if (sendBar.classList.contains("animate-bar"))
        return;
    
    if (e.keyCode == 13) {
        submitText();
    }
};

function submitText() {
    if (answerInput.value == null || answerInput.value.length == 1)
        return;

    var answer = answerInput.value.trim();

    if (answer != "") {
        animateProgressBar();
        socket.emit("send text answer", { userId: socket.id, text: answer });
        answerInput.value = "";
    }
}

function animateProgressBar(){
    sendText.innerText = "Thanks!";
    sendBar.classList.add("animate-bar");
}

function endAnimateProgressBar(){
    sendBar.classList.remove("animate-bar");
    sendText.innerText = "Send";
}

sendBar.addEventListener("animationend", () => {
    endAnimateProgressBar();
});